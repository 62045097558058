<template lang="html" src="./pageLogout.template.vue"></template>

<style lang="scss" src="./pageLogout.scss"></style>

<script>
export default {
  name: 'PageLogout',
  created() {
    /**
     * Delete Cookies
     */
    this.$deleteCookie('_pu');
    this.$deleteCookie('_pp');
    /**
     * Delete API Instance
     */
    this.$apiInstance.apiClient.authentications[
      'JWTAuth'
    ].accessToken = null;
    /**
     * Reload the App
     */
    location.href = '/';
  },
};
</script>
